<template>
  <b-container class="mb-5">
    <b-row>
      <b-col
        lg="10"
        sm="12"
        xs="12"
        md="10"
        offset-lg="1"
        offset-md="1"
      >
        <b-card
          class="hubu-bg-red"
          :header="$t('create_template')"
          header-tag="header"
        >
          <b-row>
            <b-col
              lg="12"
              sm="12"
            >
              <h3 class="m-2">
                {{ $t("create_template") }}
              </h3>
              <p>{{ $t("create_template_description") }}</p>
            </b-col>
            <b-col
              lg="12"
              sm="12"
              class="text-center"
            >
              <template v-if="decks">
                <b-button
                  v-for="(deck, index) in decks"
                  ref="levelBtns"
                  :key="index"
                  data-selected="false"
                  :data-key="JSON.stringify(deck)"
                  class="m-3"
                  :disabled="ongoingRequest"
                  :style="
                    'border-color: ' +
                      deck.bg_color +
                      '; background-color: #fff; color: ' +
                      deck.bg_color +
                      ';'
                  "
                  @click="selectDeck"
                >
                  {{ deck.subtitle }}
                </b-button>
              </template>
            </b-col>
          </b-row>

          <template v-if="selected != null">
            <b-row>
              <b-col lg="12">
                <b-form-group
                  id="fieldset-template-name"
                  :description="$t('template_name')"
                  :label="$t('template_name')"
                  label-for="template-name"
                  :state="templateNameState"
                  valid-feedback="Perfect"
                  :invalid-feedback="invalidFeedback"
                >
                  <b-form-input
                    id="template-name"
                    v-model="templateName"
                    :disabled="ongoingRequest"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="12">
                <b-form-group
                  id="fieldset-template-description"
                  :description="$t('template_description')"
                  :label="$t('template_description')"
                  label-for="template-description"
                >
                  <b-form-textarea
                    id="template-description"
                    v-model="templateDescription"
                    :disabled="ongoingRequest"
                    rows="8"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="12"
                sm="12"
              >
                <b-form-checkbox
                  v-model="templateIsPublic"
                  :disabled="ongoingRequest"
                >
                  Public
                </b-form-checkbox>
                {{ $t("template_public_private") }}
              </b-col>
            </b-row>

            <b-row
              v-if="selected"
              class="mt-5"
            >
              <b-col
                lg="12"
                sm="12"
              >
                <b-row>
                  <b-col
                    lg="2"
                    sm="2"
                  >
                    <b-form-checkbox
                      v-model="selectedAll"
                      :disabled="ongoingRequest"
                      :value="true"
                      :unchecked-value="false"
                    >
                      {{ $t('select_all') }}
                    </b-form-checkbox>
                  </b-col>

                  <b-col
                    lg="5"
                    sm="5"
                  >
                    Action
                  </b-col>
                  <b-col
                    lg="5"
                    sm="5"
                  >
                    Question
                  </b-col>
                </b-row>

                <hr />


                <cards-list
                  :template-cards="currentDeck.cards"
                  :deck="currentDeck"
                  :on-selected-change="handleSelectedChange"
                  :selected-all="selectedAll"
                  :is-base-template="ongoingRequest"
                ></cards-list>

                <!-- <b-row class="mt-1" v-for="(card, index) in currentDeck.cards" :key="index">
                  <b-col lg="2" sm="2">
                    <b-form-checkbox
                      ref="cardSelector"
                      v-model="selectedCards"
                      :data-card-title="card.action"
                      :data-card-subtitle="card.question"
                      :data-card-winks="card.winks"
                      :data-card-number="card.number"
                      :value="card.id"
                    ></b-form-checkbox>
                  </b-col>

                  <b-col lg="8" sm="8">{{ card.question }}</b-col>

                  <b-col lg="2" sm="2"> {{ card.winks ? "Wink" : "Feedback" }} </b-col>
                </b-row> -->
              </b-col>
            </b-row>
          </template>
          
          <b-row v-if="selected != null && templateName && selectedIds.length > 0 && langs.length > 0">
            <b-col
              lg="12"
              class="text-center mt-5"
            >
              <b-spinner
                v-if="ongoingRequest"
                label="Loading..."
              ></b-spinner>
              <b-button
                v-else
                size="md"
                class="btn-red"
                @click="save"
              >
                {{ $t('create_template') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CardsList from "./CardsList.vue";
import { omit } from "lodash";

export default {
  name: "CreateTemplate",

  components: { CardsList },

  data () {
    return {
      templateName: "",
      templateDescription: "",
      templateIsPublic: true,
      deckData: null,
      deckCards: [],
      selected: null,
      langs: [this.$i18n.locale],
      currentDeck: null,
      templateOptions: ["Standard", "Wink", "Feedback"],
      selectedIds: [],
      selectedAll: true,
      ongoingRequest: false,
    };
  },
  computed: {
    decks () { return  this.$store.getters.getDecks[this.$i18n.locale]; },
    user: {
      get () {
        return this.$store.getters.getUserData;
      },
    },
    templateNameState: {
      get () {
        return this.templateName.length >= 4;
      },
    },
    invalidFeedback () {
      if (this.templateName && this.templateName.length < 4) {
        return "Enter at least 3 characters.";
      }

      return null;
    },
  },
  watch: {
    selectedAll (selected) {
      this.selectedAll = selected;
    },
  },
  async mounted () {
    !this.$store.getters.getOrganization && await this.$store.dispatch("getOrganizations");
    this.$store.getters.getDecks[this.$i18n.locale].length == 0 && await this.$store.dispatch("getDecks");
  },
  methods: {
    handleSelectedChange (selectedIds){
      this.selectedIds = selectedIds;
    },
    selectAll (value) {
      this.currentDeck.cards = this.currentDeck.cards.map(card => ({ ...card, enabled: value }));
    },

    switchAccess () {
      this.templateIsPublic = !this.templateIsPublic;
    },

    save () {
      if (this.deckData == null || this.templateName.length < 4) {
        this.$toast.open({
          message: "Please make sure to select a level and fill the name",
          type: "error",
        });
        return false;
      }
      
      const cards = this.currentDeck.cards.map(card => ({ 
        ...card,
        image: card.image,
        action: card.action,
        question: card.question,
        enabled: this.selectedIds.includes(card.id), 
      }));
      
      
      this.langs.forEach((lang) => {
        var data = {
          name: this.templateName,
          description: this.templateDescription,
          bg_color: this.deckData.bg_color,
          text_color: this.deckData.text_color,
          subtitle: "Custom deck",
          private: !this.templateIsPublic,
          cards: cards.map(card => omit(card, "id")),
          locale: lang,
          deck: this.selected,
          owner: this.user.id,
          hat: this.user.hats[0].id,
        };
        this.ongoingRequest = true;
        this.$store
          .dispatch("createTemplate", data)
          .then(() => {
            this.$router.push({
              name: "ChooseCards",
            });
            this.$toast.open(this.$t("template_created"), {
              // override the global option
              position: "bottom",
            });
          })
          .catch(() => {
            this.$toast.open({ message: "Unexpected error", type: "error" });
          });
      });
    },

    selectDeck (e) {
      var btns = this.$refs.levelBtns;
      var data = JSON.parse(e.target.getAttribute("data-key"));
      var selected = e.target.getAttribute("data-selected");

      btns.forEach((btn) => {
        var btnData = JSON.parse(btn.getAttribute("data-key"));
        btn.setAttribute("data-selected", "false");
        btn.style.backgroundColor = "#fff";
        btn.style.color = btnData.bg_color;
      });

      if (selected == "false") {
        e.target.setAttribute("data-selected", "true");
        e.target.style.backgroundColor = data.bg_color;
        e.target.style.color = data.text_color;
        this.selected = data.id;
        this.deckData = data;
        this.currentDeck = this.decks.find(val => val.id == data.id);
      } else {
        e.target.setAttribute("data-selected", "false");
        e.target.style.backgroundColor = "#fff";
        e.target.style.color = data.bg_color;
        this.selected = null;
      }
    },

    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object"
        ? this.$store.getters.getAppTexts[text].content
        : text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu-bg-red {
  border: 1px solid $red;
}

.hubu-btn-red {
  background-color: $red;
  color: $white;
  font-weight: bold;
  border: 0px;
}

.btn-white {
  background-color: $white;
  color: $red;
  font-weight: bold;
  border: 1px solid $red;
}

header {
  background-color: $red;
  border-color: $red;
  font-size: 24px;
  color: $orange;
  font-weight: bold;
}

.templateIsPublic {
  background-color: $red;
  color: $white;
  border-color: $red;
}
.hubu-checkbox {
  &:checked ~ label {
    &:before {
      border: 1555px solid $red;
    }
    &:after {
      transform: rotate(-45deg) scale(1);
    }
  }
}
</style>


